"use client";

import React from "react";
import Image from "next/image";
import authBack from "@/public/images/LeftSection.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslate } from "@/components/base/providers/TranslateProv";

const data = {
  ar: [
    {
      title:
        "استفد من التحديثات والمعلومات الحصرية حول الجامعات والتخصصات، وابقَ على اطلاع دائم بأحدث الفرص الأكاديمية",
      subTitle:
        "ابقَ على اطلاع دائم بأحدث الفرص الأكاديمية من خلال التحديثات والمعلومات الحصرية حول الجامعات والتخصصات.",
    },
    {
      title:
        "بإنشاء حسابك، ستحصل على استشارات حصرية ودعم مباشر لمساعدتك في اتخاذ قرارات أكاديمية مستنيرة",
      subTitle:
        "أنشئ حسابك الآن لتحصل على استشارات حصرية ودعم مباشر لاتخاذ قرارات أكاديمية مستنيرة.",
    },
    {
      title:
        "سجل الآن لفتح باب الوصول إلى جميع ميزات الموقع واستشارات سفير الجامعات المتخصصة في اختيار الجامعة والتخصص الأنسب لك",
      subTitle:
        "سجل الآن للوصول إلى ميزات الموقع واستشارات اختيار الجامعة والتخصص الأنسب لك",
    },
  ],

  en: [
    {
      title:
        "Benefit from exclusive updates and information about universities and specializations, and stay informed about the latest academic opportunities.",
      subTitle:
        "Stay informed about the latest academic opportunities through exclusive updates and information about universities and specializations.",
    },
    {
      title:
        "By creating your account, you'll receive exclusive consultations and direct support to help you make informed academic decisions.",
      subTitle:
        "Create your account now to receive exclusive consultations and direct support for making informed academic decisions.",
    },
    {
      title:
        "Register now to gain access to all site features and specialized consultations from University Ambassador to help you choose the most suitable university and specialization.",
      subTitle:
        "Register now to access site features and consultations for choosing the most suitable university and specialization for you.",
    },
  ],
};

const Index = () => {
  const { locale }: { locale: "ar" | "en" } = useTranslate();

  return (
    <div className="flex flex-col items-center justify-center">
      <Image src={authBack} alt="authBack" />
      <div className="bg-white rounded-[20px] max-w-[333px] md:max-w-[633px] mx-auto -mt-32 md:p-10 p-5">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={10}
          slidesPerView={1}
          autoplay={{ delay: 2000 }}
          pagination={{
            clickable: true,
            renderBullet(index, className) {
              return `<span class="${className} !rounded-[10px] !top-2 !bg-primaryColor !w-[45px] !h-[6px] transition"></span>`;
            },
          }}
          dir={locale === "ar" ? "rtl" : "ltr"}
          className="!pb-16"
        >
          {data[locale]?.map((item, index) => (
            <SwiperSlide key={index}>
              <p className="text-[24px] text-darkText font-[600] text-center mb-3">
                {item.title}
              </p>
              <p className="text-[16px] md:text-18 text-descriptionText text-center">
                {item.subTitle}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Index;

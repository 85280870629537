import { Field } from "formik";
import React, { useState } from "react";
import Image from "next/image";
import namee from "@/public/images/name.svg";
import openEye from "@/public/images/openEye.svg";
import closeEye from "@/public/images/closeEye.svg";

const index = ({
  type,
  name,
  label,
  placeholder,
  icon,
}: {
  type: string;
  name: string;
  label?: string;
  placeholder: string;
  icon?: any;
}) => {
  const [showPassword, setshowPassword] = useState(false);
  return (
    <div>
      {label && (
        <p className=" text-16 text-darkText font-[500] mb-2">{label}</p>
      )}

      <div className=" relative">
        <Field
          type={showPassword ? "text" : type}
          name={name}
          className=" h-[50px] bg-[#F8F9FA] rounded-[10px] px-10 w-full flex items-center placeholder:text-14 placeholder:text-descriptionText outline-none"
          placeholder={placeholder}
        />
        <Image
          src={icon}
          alt="name"
          className=" absolute right-3 top-1/2 -translate-y-1/2"
        />
        {type === "password" && (
          <Image
            src={!showPassword ? closeEye : openEye}
            alt="name"
            className=" absolute left-3 top-1/2 -translate-y-1/2 cursor-pointer"
            onClick={() => setshowPassword(!showPassword)}
          />
        )}
      </div>
    </div>
  );
};

export default index;

import { apiNoTokenAuthSlice } from "@/redux/app/api/apiSlice";

export const authNoTokenApiSlice = apiNoTokenAuthSlice.injectEndpoints({
  endpoints: (builder) => ({
    dashboardLogin: builder.mutation({
      // dashboard
      query: (loginValues) => ({
        url: "/api/dashboard/login",
        method: "POST",
        body: { ...loginValues },
      }),
    }),
    // anonymous
    login: builder.mutation({
      query: (loginValues) => ({
        url: "/api/login",
        method: "POST",
        body: { ...loginValues },
      }),
    }),
    register: builder.mutation({
      query: (registerValues) => ({
        url: "/api/register",
        method: "POST",
        body: { ...registerValues },
      }),
    }),
    forgetPassword: builder.mutation({
      query: (values) => ({
        url: "/api/forgot-password",
        method: "POST",
        body: { ...values },
      }),
    }),

    verifyOTP: builder.mutation({
      query: (values) => ({
        url: "/api/verify",
        method: "POST",
        body: { ...values },
      }),
    }),
    resendCode: builder.mutation({
      query: (values) => ({
        url: "/api/resend-code",
        method: "POST",
        body: { ...values },
      }),
    }),
    resetPassword: builder.mutation({
      query: (values) => ({
        url: "/api/reset-password",
        method: "POST",
        body: { ...values },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useDashboardLoginMutation,
  useRegisterMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useVerifyOTPMutation,
  useResendCodeMutation,
} = authNoTokenApiSlice;

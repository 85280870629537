"use client";
import LeftSection from "@/components/Auth/LeftSection";
import React, { useEffect, useState } from "react";
import logo from "@/public/images/logo.svg";
import emailGrey from "@/public/images/emailGrey.svg";
import password from "@/public/images/password.svg";
import Image from "next/image";
import Link from "next/link";
import { Field, Form, Formik } from "formik";
import CustomInput from "@/components/shared/CustomInput";
import "react-phone-number-input/style.css";
import MyLink from "@/components/shared/myLink/MyLink";
import { redirect, useRouter } from "next/navigation";
import { useAppDispatch } from "@/redux/app/hooks";
import { useLoginMutation } from "@/redux/features/api/auth/authNoTokenApiSlice";
import { setCredentials } from "@/redux/features/local/auth/authSlice";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import { toast } from "react-toastify";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

const Page = () => {
  const { locale } = useTranslate();

  const dispatch = useAppDispatch();
  const [login, { isLoading, isError, data, error, isSuccess }] =
    useLoginMutation();
  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      const userData = await login(values).unwrap();
      dispatch(setCredentials({ ...userData?.data, email: values?.email }));
    } catch (err) {
      console.log("Login failed:", err);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(
        locale === "ar" ? "تم تسجيل الدخول بنجاح" : "Login Successful"
      );
      redirect(`/`);
    }
    if (error && !isSuccess) {
      if ("data" in error) {
        const fetchBaseQueryError = error as FetchBaseQueryError;
        toast.error(fetchBaseQueryError.data?.message || "حدث خطأ ما");
      } else {
        toast.error("حدث خطأ ما");
      }
    }
  }, [isSuccess, error]);

  return (
    <div>
      <div className=" bg-[#F8F9FA]">
        <div className=" container py-[50px] lg:py-[100px] flex flex-col lg:flex-row items-center gap-5">
          <div className=" lg:grow h-fit min-w-[50%] bg-white p-5 rounded-[10px] flex flex-col gap-5">
            <Image src={logo} alt="logo" className=" mx-auto my-5" />
            <div className=" flex  border border-[#F8F9FA] rounded-[10px] bg-[#F8F9FA] p-1 w-fit mx-auto mb-5">
              <Link
                href={"/signup"}
                className=" text-16 text-descriptionText font-[500] bg-white py-3 px-5 rounded-[10px]"
              >
                {locale === "ar" ? "انشاء حساب" : "Sign up"}
              </Link>
              <Link
                href={"/login"}
                className=" text-16 text-white font-[500] bg-primaryColor py-3 px-5 rounded-[10px]"
              >
                {locale === "ar" ? "تسجيل الدخول" : "Login"}
              </Link>
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {(props) => (
                <Form>
                  <div className=" grid grid-cols-1 mb-3  gap-5">
                    <CustomInput
                      type="email"
                      name="email"
                      label={locale === "ar" ? "البريد الالكتروني" : "Email"}
                      placeholder="abdo35@gmail.com"
                      icon={emailGrey}
                    />
                    <CustomInput
                      type="password"
                      name="password"
                      label={locale === "ar" ? "كلمة المرور" : "Password"}
                      placeholder="كلمة المرور"
                      icon={password}
                    />
                  </div>
                  <MyLink
                    className=" text-16 text-primaryColor"
                    href={`/forgetPassword`}
                  >
                    هل نسيت كلمة المرور؟{" "}
                  </MyLink>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className=" my-10 bg-primaryColor text-16 text-white font-[500] flex items-center justify-center rounded-[10px] h-[60px] w-full"
                  >
                    {locale === "ar" ? "تسجيل الدخول" : "Login"}
                  </button>
                  <span className=" bg-[#EBEBEB] w-full h-[1px] block"></span>
                  <div className=" flex items-center justify-center mt-10 gap-5">
                    <p className=" text-14 text-descriptionText">
                      ©
                      {locale === "ar"
                        ? "جميع الحقوق محفوظه."
                        : "All rights reserved."}
                    </p>
                    <MyLink
                      href={"/privacy-policy"}
                      className=" text-14 text-primaryColor"
                    >
                      {locale === "ar" ? "سياسة الخصوصية" : "Privacy Policy"}
                    </MyLink>
                    <span className=" bg-[#ACB5BB] w-[1px] h-[10px] rounded-full block" />
                    <MyLink
                      href={"/terms-and-conditions"}
                      className=" text-14 text-primaryColor"
                    >
                      {locale === "ar"
                        ? "الشروط و الأحكام"
                        : "Terms and Conditions"}
                    </MyLink>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <LeftSection />
        </div>
      </div>
    </div>
  );
};

export default Page;
